import React, { useState, useEffect } from 'react';
import { graphql, Link /*, navigate*/ } from 'gatsby';
import styled from 'styled-components';
import Footer from './../../components/Footer';
function Signup({ pageContext: { contentful_id }, data }) {
  const [thankYouShowing, setThankYouShowing] = useState(false);
  const [email, setEmail] = useState('');
  const [attributes, setAttributes] = useState({
    FIRSTNAME: '',
    LASTNAME: '',
    PROFESSION: '',
    COMPANY: '',
  });
  const [submitting, setSubmitting] = useState(false);

  const { contentfulBook } = data;

  const apiKey =
    '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';

  // const openPdf = () => {
  //   window.location.href = contentfulBook.bookPdf.url;
  // };

  useEffect(() => {
    if (localStorage.getItem('theolitaguide__signedup') === 'true') {
      setThankYouShowing(true);
    }
  }, []);

  // const THANKYOU_LENGTH_MS = 2000;

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (thankYouShowing) return;
    setSubmitting(true);

    try {
      if (submitting) return;

      const url = 'https://api.sendinblue.com/v3/contacts';
      const togDownloadsListId = 5;

      // add to all contacts
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          accept: 'application/json',
          'content-type': 'application/json',
          'api-key': apiKey,
        },
        body: JSON.stringify({
          email,
          attributes: { ...attributes },
        }),
      }).then((res) => res.json());

      // add to TOG DOWNLOADED list.
      const response2 = await fetch(
        `https://api.sendinblue.com/v3/contacts/lists/${togDownloadsListId}/contacts/add`,
        {
          method: 'POST',
          headers: {
            accept: 'application/json',
            'content-type': 'application/json',
            'api-key': apiKey,
          },
          body: JSON.stringify({
            ids: [response.id],
          }),
        }
      ).then((res) => res.json());

      localStorage.setItem('theolitaguide__signedup', 'true');

      setThankYouShowing(true);
    } catch (error) {
      console.log('ERROR: ', error);

      localStorage.setItem('theolitaguide__signedup', 'true');
      setThankYouShowing(true);
    }
  };

  const handleChange = (e) => {
    setAttributes((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  return (
    <Wrapper>
      <Form onSubmit={handleSubmit}>
        {thankYouShowing ? (
          <>
            <p className="instructions">
              Thank you, you can now download the guide&nbsp;
              <a href={contentfulBook.bookPdf.url}>here</a> or&nbsp;
              <Link to="/">go back to the homepage</Link> to access any other
              guide you're interested in.
            </p>

            {/* <div className="form__submit">
              <button onClick={() => navigate('/')}>GO BACK</button>
              <button onClick={openPdf}>DOWNLOAD</button>
            </div> */}
          </>
        ) : (
          <>
            <p className="instructions">
              Fill in your details to get full access to our guides.
            </p>
            <div className="form__row">
              <Input
                type="text"
                name="FIRSTNAME"
                onChange={handleChange}
                placeholder="FIRST NAME*"
                required
              />
              <Input
                type="text"
                name="LASTNAME"
                onChange={handleChange}
                placeholder="LAST NAME*"
                required
              />
              <Input
                type="email"
                name="email"
                onChange={(e) => setEmail(e.target.value)}
                placeholder="EMAIL ADDRESS*"
                required
              />
            </div>

            <div className="form__row">
              <Input
                type="text"
                name="PROFESSION"
                onChange={handleChange}
                placeholder="PROFESSION"
              />

              <Input
                type="text"
                name="COMPANY"
                onChange={handleChange}
                placeholder="COMPANY"
              />
            </div>
          </>
        )}
        {!thankYouShowing && (
          <div className="form__submit">
            <button type="submit">SUBMIT</button>
          </div>
        )}
      </Form>

      <div className="wrapper__footer">
        <Footer />
      </div>
    </Wrapper>
  );
}

export const query = graphql`
  query getSingleBook($title: String) {
    contentfulBook(title: { eq: $title }) {
      id
      title
      bookPdf {
        url
      }
    }
  }
`;

const Wrapper = styled.main`
  font-family: 'Arial', sans-serif;

  .instructions {
    font-family: 'Courier New', Courier, monospace;
    font-size: 24px;
    text-align: left;
    margin-bottom: 20px;

    @media screen and (max-width: 1100px) {
      margin-left: 10px;
    }

    @media screen and (max-width: 600px) {
      text-align: center;
      margin-left: 0;
    }
  }

  position: relative;
  height: 100vh;

  .wrapper__footer {
    position: absolute;
    bottom: 0;
    width: 100%;
  }
`;

const Form = styled.form`
  padding: 8% 0 0;
  display: flex;
  flex-direction: column;
  z-index: 1;
  background: transparent;
  max-width: 1200px;
  margin: auto;
  text-align: center;
  justify-content: flex-start;
  position: relative;
  min-height: calc(84vh - 48px);
  flex-grow: 1;

  a {
    font-family: 'Courier New', Courier, monospace;
    font-weight: 400;
    font-size: 24px;
    cursor: pointer;
    text-decoration: underline;
    color: #fff;
    border: 0;
    background: transparent;
  }

  @media screen and (max-width: 600px) {
    padding: 8% 8% 0;
  }

  .form__row {
    display: flex;
    flex-direction: row;
    width: 100%;

    @media screen and (max-width: 600px) {
      flex-direction: column;
      flex-wrap: wrap;
    }
  }

  .form__submit {
    position: absolute;
    bottom: 50%;
    left: 0;
    button {
      font-family: 'Courier New', Courier, monospace;
      font-weight: 400;
      font-size: 24px;
      cursor: pointer;
      text-decoration: underline;
      color: #fff;
      border: 0;
      background: transparent;

      &:first-of-type {
        margin-right: 10px;
      }
    }

    @media screen and (max-width: 600px) {
      position: initial;
      margin-top: 40px;
      width: fit-content;
      margin-left: auto;
      margin-right: auto;
    }
  }
`;

const Input = styled.input`
  background: transparent;
  width: 100%;
  border: 1px solid #fff;
  text-align: left;
  margin: 5px 5px;
  padding: 10px;
  box-sizing: border-box;
  font-size: 1.4rem;
  color: #fff;
  padding-top: 20px;
  padding-bottom: 20px;

  &::placeholder {
    color: #fff;
    font-size: 1.4rem;
    text-align: left;
  }

  @media screen and (max-width: 600px) {
    text-align: center;

    &::placeholder {
      text-align: center;
    }
  }

  &[type='submit'] {
    cursor: pointer;
  }
`;

export default Signup;
